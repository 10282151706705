import React from 'react';
import { Site } from './components/Site'

const App = () => {
  return (
    <Site/>
  );
}

export default App;
